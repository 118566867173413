/** Classes */
import StateService from "../../Services/State.js";

/** Components */
import Menu from "./Menu.js";

let instance = null;

export default class Header {
    constructor( _element, hideMenu ) {

        if (instance) return instance;
        instance = this;

        /** Init */
        this.element = _element;
        this.hideMenu = hideMenu;

        /** Elements */
        this.menu = null;

        this.create();
    }

    openMenu() {
        StateService.menu = true;
    }

    create() {
        this.element.className = `Header ${ this.hideMenu ? 'hide-menu' : '' }`;
        this.element.innerHTML = `
            <div id="logo">
                <img class="logo-icon" src="/images/icon.png" />
            </div>

            <div id='hamburger'>
                <img id='hamburger-closed' src='/images/hamburger/black.svg' class='icon' draggable='false' />
            </div>

            <div id='menu'></div>
        `;

        this.menu = new Menu( document.getElementById( 'menu' ) );

        document.getElementById( 'hamburger' ).onclick = this.openMenu;
    }

    destroy() {
        instance = null;
    }
}