const privates = {
    all: {},
    datasources: {}
}

class Content {
    constructor() {}

    get all () {
        return privates.all;
    }

    set all ( val ) {
        privates.all = val;
    }

    get datasources() { 
        return privates.datasources
    }

    set datasources( val ) {
        privates.datasources = val;
    }

    get prestate () {
        return this.all.diary[ 0 ].prestate[ 0 ];
    }

    get diary () {
        return this.all.diary;
    }

    get cities () {
        return this.all.cities;
    }

    get lights () {
        return this.all.lights;
    }

    get holding () {
        return this.all.holding;
    }

    get sources() {
        const acc = [];
        const stack = [ Object.assign( {}, this.all ) ];
    
        while ( stack?.length > 0 ) {
            const currentObj = stack.pop();
            Object.keys( currentObj ).forEach( ( key ) => {
                if ( typeof currentObj[ key ] === 'object' && currentObj[ key ] !== null ) {
                    stack.push( currentObj[ key ] );
                    // If the object is an asset push it into the array
                    if ( currentObj[ key ]?.component === 'asset' ) {
                        const asset = {
                            name: currentObj[ key ].name,
                            type: currentObj[ key ].type,
                            path: currentObj[ key ].asset.filename
                        }
                        
                        if ( asset.path && !acc.find( ( item ) => item.name === asset.name ) ) {
                            acc.push( asset );
                        }
                    }
                }
            });
        }

        return acc;
    }

    getDiaryEntryById( val ) {
        return this.diary[ 0 ].days.find( ( entry ) => entry._uid === val );
    }

    getDiaryEntryIds( ) {
        return this.diary[ 0 ].days.map( ( entry ) => entry._uid );
    }

    getDiaryEntryIndexById( val ) {
        return this.diary[ 0 ].days.findIndex( ( entry ) => entry._uid === val );
    }

    findIfContinuingJourney( current, next ) {
        const index = this.getDiaryEntryIndexById( current );
        return index !== this.diary[ 0 ].days.length - 1 && this.diary[ 0 ].days[ index + 1 ]._uid === next;
    }

    getPreviousDiaryEntryById( val ) {
        const index = this.getDiaryEntryIndexById( val );
        return index !== 0 ? this.diary[ 0 ].days[ index - 1 ] : this.prestate;
    }

    getNextDiaryEntryById( val ) {
        const index = this.getDiaryEntryIndexById( val );
        return this.diary[ 0 ].days[ index + 1 ];
    }

    getDiaryEntries( ) {
        return this.diary[ 0 ].days;
    }

    getLightSettingsById( id ) {
        return this.lights.find( ( light ) => light.id === id );
    }

    getCityByName( name ) {
        return this.cities.find( ( item ) => item.name === name );
    }

    async getVersion() {
        const response = await fetch( `https://api.storyblok.com/v2/cdn/spaces/me?token=TyPGAVIbW9n5rkOrBHbNQAtt` )
        const data = await response.json();
        return data.space.version;
    }
    
    async getContent( version, contentType ) {
        let url = `https://api.storyblok.com/v2/cdn/stories/application?token=TyPGAVIbW9n5rkOrBHbNQAtt&cv=${ version }`;
        url = contentType ? `${ url }&version=${ contentType }` : url;
        const response = await fetch( url )
        const data = await response.json();
        return data.story.content;
    }
    
    async getDatasources( type ) {
        let url = `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=${ type }&token=TyPGAVIbW9n5rkOrBHbNQAtt&t=${ Date.now() }`;
        const response = await fetch( url )
        const data = await response.json();
        return data;
    }

    async onInit() {
        try {
            // Find if should get different version of storyblok content
            const urlParams = new URL( document.location ).searchParams;
            const version = await this.getVersion();
            const contentType = urlParams.get( 'content-type' );

            this.all = await this.getContent( version, contentType );
            this.datasources[ 'trackers' ] = await this.getDatasources( 'trackers' );
            this.datasources[ 'scenery' ] = await this.getDatasources( 'scenery' );

            return this.all;
        } catch( err ) {
            throw new Error( err );
        }
    }
}

export default new Content();