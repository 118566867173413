/** Variables */
let instance = null;

export default class Loading {
    constructor( _element ) {
        // Singleton
        if (instance) return instance;
        instance = this;

        /** Elements */
        this.element = _element;

        this.onInit();
    }

    /** Functions */
    create() {
        this.element.className = 'Bootup transform-in';
        this.element.innerHTML = `
            <div class="bootup-title-container">
                <h1 class="bootup-title title-l">Loading</h1>
            </div>
            <div class="working">

            </div>
        `;
        setTimeout( () => this.element.classList.remove( 'transform-in' ) );
    }

    /** Lifecycles */
    onInit() {
        this.create();
    }

    onClose() {
        this.element.classList.add( 'transform-out' );
    }

    destroy() {
        instance = null;
    }
}