/** Libraries */
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import TWEEN from '@tweenjs/tween.js';

/** Classes */
import Experience from './Experience.js';

export default class Camera {

    constructor( ) {
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.renderer = this.experience.renderer;

        this.debug = this.experience.debug;

        this.updating = [];
        this.changingOffset = null;

        this.setInstance();
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera( 45, this.sizes.width / this.sizes.height, 0.1, 1000 );
        this.instance.position.set( 200, 200, 200);
        this.controls = new OrbitControls( this.instance, this.canvas );
        this.controls.enableDamping = true;
        this.controls.enablePan = false;

        this.addDebug();

        this.scene.add( this.instance );
    }

    addDebugPointer() {
        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
        const mesh = new THREE.Mesh( geometry, material );
        mesh.visible = false;
        this.scene.add( mesh );

        return mesh;
    }

    // Add debugging to try camera positions
    addDebug() {
        if ( this.debug.active ) {
            // Folders
            const debugFolder = this.debug.ui.addFolder('Camera Settings').close();
            const debugZoomFolder = debugFolder.addFolder('Zoom Settings').close();
            const debugPositionFolder = debugFolder.addFolder('Position Settings').close();
            const targetHelper = this.addDebugPointer();

            // Zoom settings
            debugZoomFolder.add( this.controls, 'maxDistance', 0, 500, 1 ).name( 'Maximum Distance' );
            debugZoomFolder.add( this.controls, 'minDistance', 0, 500, 1 ).name( 'Minimum Distance' );

            // Position settings
            debugPositionFolder.add( this.instance.position, 'x', -500, 500, 0.01 ).name( 'Camera Position X' );
            debugPositionFolder.add( this.instance.position, 'y', 0, 200, 0.01 ).name( 'Camera Position Y' );
            debugPositionFolder.add( this.instance.position, 'z', -500, 500, 0.01 ).name( 'Camera Position Z' );
            debugPositionFolder.add( targetHelper, 'visible' ).name( 'Toggle Camera Target Helper' )
            debugPositionFolder.add( this.controls.target, 'x', -500, 500, 0.01 ).name( 'Camera Target X' ).onChange( () => targetHelper.position.x = this.controls.target.x );
            debugPositionFolder.add( this.controls.target, 'y', 0, 30, 0.01 ).name( 'Camera Target Y' ).onChange( () => targetHelper.position.y = this.controls.target.y );
            debugPositionFolder.add( this.controls.target, 'z', -500, 500, 0.01 ).name( 'Camera Target Z' ).onChange( () => targetHelper.position.z = this.controls.target.z );
            debugPositionFolder.add( this.controls, 'maxPolarAngle', 0, Math.PI, 0.01 ).name( 'Lowest Height' );
            debugPositionFolder.add( this.controls, 'minPolarAngle', 0, Math.PI / 2, 0.01 ).name( 'Highest Height' );
        }
    }

    // Move camera to correct location and set limits
    completedTimeline( instant ) {
        const camera = this.experience.state.camera[ 0 ];
        const position = { x: parseFloat( camera.position[ 0 ].x ), y: parseFloat( camera.position[ 0 ].y ), z: parseFloat( camera.position[ 0 ].z ) };

        if ( instant ) {
            this.instance.position.set( position.x, position.y, position.z );
        } else {
            const animation = new TWEEN.Tween( this.instance.position )
                .to( { x: position.x, y: position.y, z: position.z }, parseFloat( camera.transition ) * 1000 )
                .easing( TWEEN.Easing.Quadratic.InOut )
                .start()
                .onComplete( () => {
                    this.controls.minPolarAngle = parseFloat( camera.polar[ 0 ].min );
                    this.controls.maxPolarAngle = parseFloat( camera.polar[ 0 ].max );
                    this.controls.minDistance = parseFloat( camera.zoom[ 0 ].min );
                    this.controls.maxDistance = parseFloat( camera.zoom[ 0 ].max );
                    this.controls.enabled = true;
                } );
            this.updating.push( animation );
        }
    }

    // Apply a flythrough
    applyTimelineSegments( remaining ) {

        const segment = remaining[ 0 ];

        if ( segment && segment.camera && segment.camera.length ) {
            const points = segment.camera
                .map( ( item ) => new THREE.Vector3( parseFloat( item.x ), parseFloat( item.y ), parseFloat( item.z ) ) );
            const curve = new THREE.CatmullRomCurve3( points );
            const time = { current: 0, duration: segment.camera_duration * 1000 }

            const animation = new TWEEN.Tween( time )
                .to( { current: time.duration }, time.duration )
                // Apply Our beizer curve here...
                .easing( TWEEN.Easing.Linear.None )
                .start()
                .onUpdate( () => {
                    const positionOnCurve = ( time.current % time.duration ) / time.duration;
                    const position = curve.getPointAt( positionOnCurve );
                    this.instance.position.copy( position );
                } )
                .onComplete( () => {
                    this.instance.position.set( 
                        parseFloat( segment.camera[ segment.camera.length - 1 ].x ),
                        parseFloat( segment.camera[ segment.camera.length - 1 ].y ),
                        parseFloat( segment.camera[ segment.camera.length - 1 ].z )
                    )

                    remaining.shift();
                    return remaining.length ? this.applyTimelineSegments( remaining ) : this.completedTimeline();

                } )
            this.updating.push( animation );
        } else {
            // Navigate to camera position
            this.completedTimeline();
        }
    }

    // Move the camera to be offset when the Slide is open
    applyOffset( val ) {
        if ( this.changingOffset ) {
            this.changingOffset.stop();
            TWEEN.remove( this.changingOffset );
        }
        
        const slideTime = 1000;
        const movement = { current: this.instance.view && this.instance.view.enabled ? this.instance.view.offsetX : 0 };
        let offset = val ? this.sizes.width * 0.2 : 0;

        this.changingOffset = new TWEEN.Tween( movement )
            .to( { current: offset }, slideTime )
            .easing( TWEEN.Easing.Quadratic.InOut )
            .start()
            .onUpdate( () => {
                this.instance.setViewOffset( this.sizes.width, this.sizes.height, movement.current, 0, this.sizes.width, this.sizes.height );
            })
    }

    onStateUpdate( instant ) {
        this.updating.forEach( ( animation ) => {
            animation.stop();
            TWEEN.remove( animation );
        } )
        this.updating = [];
        
        // Turn off limits
        this.controls.enabled = false;
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = Math.PI;
        this.controls.minDistance = 0;
        this.controls.maxDistance = 1000;

        // Apply flythroughs and set camera positions
        if ( !instant ) {
            this.applyTimelineSegments( [ ...this.experience.state.timeline ] );
        } else {
            this.completedTimeline( instant );
        }
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update() {
        this.controls.update();
        const tracker = this.experience.scene.getObjectByName( 'tracker' );
        this.controls.target.copy( tracker.position );
    }
}