/** Services */
import Content from "../../Services/Content.js";
import State from "../../Services/State.js";

/** Components */
import DiaryPage from "../Core/DiaryPage.js";
import Header from "../Header/Header.js";
import DateSelector from "./DateSelector.js";
import Navigation from "./Navigation.js";

/** Variables */
let instance = null;

export default class ExperiencePage {
    constructor( _element ) {
        // Singleton
        if (instance) return instance;
        instance = this;

        /** Bindings */
        this.previous = this.previous.bind( this );
        this.next = this.next.bind( this );
        this.explore = this.explore.bind( this );
        this.close = this.close.bind( this );

        /** Elements */
        this.element = _element;
        this.header = null;
        this.date = null;

        this.navigationElement = null;
        this.navigation = null;
        this.entryElement = null;
        this.entry = null;

        this.currentId = null;
        this.nextItem = null;
        this.previousItem = null;

        this.delay = null;

        this.onInit();
    }

    /** Functions */
    /** Adds the header to page */
    addHeader() {
        if ( this.header ) return;
        this.header = new Header( document.getElementById( 'ExperiencePage_header' ) );
    }

    /** Adds Date Selector to the screen - Never Removed */
    addDate() {
        if ( this.date ) return;
        this.date = new DateSelector( document.getElementById( 'ExperiencePage_date' ) );
    }

    menu() {
        State.menu = true;
    }

    /** Change state to previous item */
    previous() {
        if ( this.previousItem._uid ) State.currentId = this.previousItem._uid;
    }

    /** Change state to next item */
    next() {
        if ( this.nextItem._uid ) State.currentId = this.nextItem._uid;
    }

    explore() {
        console.log( 'EXPLORE!!' );
        this.hideCurrent();
        this.showEntry();
    }

    close() {
        console.log( 'CLose' );
        this.hideCurrent();
        this.showNavigation( true );
    }

    hideCurrent() {
        this.removeEntry();
        this.removeNavigation();
    }

    removeEntry() {
        if ( this.entry ) this.entry.destroy();
        if ( this.entryElement ) this.entryElement.remove();
        this.nextItem = null;
        this.previousItem = null;
        this.entry = null;
        this.entryElement = null;
        document.getElementById( 'ExperiencePage_entry' ).classList.add( 'hidden' );
    }

    showEntry() {
        if ( !this.entryElement && !this.navigationElement ) {
            console.log( 'creating something here??' );
            this.entryElement = document.createElement( 'div' );
            document.getElementById( 'ExperiencePage_entry' ).appendChild( this.entryElement );
            document.getElementById( 'ExperiencePage_entry' ).classList.remove( 'hidden' );

            const pageData = Content.getDiaryEntryById( this.currentId ).content[ 0 ];
            this.nextItem = Content.getNextDiaryEntryById( this.currentId );
            this.previousItem = Content.getPreviousDiaryEntryById( this.currentId );

            this.entry = new DiaryPage(
                this.entryElement,
                pageData,
                null,
                this.previousItem && this.previousItem.name !== "Pre State" ? { title: this.previousItem.name, callback: this.previous } : null,
                this.nextItem ? { title: this.nextItem.name, callback: this.next } : null
            );

            State.cameraOffset = true;
            console.log( 'its done' );

        }
    }

    removeNavigation() {
        if ( this.navigation ) this.navigation.destroy();
        if ( this.navigationElement ) this.navigationElement.remove();
        this.nextItem = null;
        this.previousItem = null;
        this.navigation = null;
        this.navigationElement = null;
        document.getElementById( 'ExperiencePage_navigation' ).classList.add( 'hidden' );
    }
    
    showNavigation( instant ) {
        console.log( 'show navigation??' );
        if ( !this.entryElement && !this.navigationElement ) {
            console.log( 'correct' );
            State.cameraOffset = false;
            this.navigationElement = document.createElement( 'div' );
            document.getElementById( 'ExperiencePage_navigation' ).appendChild( this.navigationElement );
                
            const pageData = Content.getDiaryEntryById( this.currentId ).content[ 0 ];
            this.nextItem = Content.getNextDiaryEntryById( this.currentId );
            this.previousItem = Content.getPreviousDiaryEntryById( this.currentId );

            this.navigation = new Navigation(
                this.navigationElement,
                this.explore,
                pageData,
                this.previousItem && this.previousItem.name !== "Pre State" ? { title: this.previousItem.name, callback: this.previous } : null,
                this.nextItem ? { title: this.nextItem.name, callback: this.next } : null,
                instant
            );

            document.getElementById( 'ExperiencePage_navigation' ).classList.remove( 'hidden' );
        }
    }

    /** There is a change to the active page */
    entryUpdate( data ) {
        console.log( 'data', data );
        // if ( this.currentId ) State.changeEntryStatus( this.currentId, 'completed' );
        State.cameraOffset = false;
        this.currentId = data;
        // State.changeEntryStatus( this.currentId, 'active' );
        
        this.hideCurrent();
        this.showNavigation();
    }

    /** Lifecycles */
    onInit() {
        this.element.className = 'ExperiencePage';
        this.element.innerHTML = `
            <div id="ExperiencePage_header"></div>
            <div id="ExperiencePage_navigation" class="hidden"></div>
            <div id="ExperiencePage_entry" class="ExperienceEntry hidden">
                <button class="close" id="ExperiencePage_entry_close">
                    <img class="close-icon" src="/images/close/close.svg" />
                    <p class="close-text title-xs">Close</p>
                </button>
            </div>
            <div id="ExperiencePage_date"></div>
        `;

        this.addHeader();
        this.addDate();

        document.getElementById( 'ExperiencePage_entry_close').addEventListener( 'click', this.close );
        // if ( State.currentStatus !== 'active' && State.currentStatus !== 'completed' ) {
        //     State.changeEntryStatus( State.currentId, 'pending' );
        // }

        // this.delay = setTimeout( () => this.explore(), parseFloat( this.data.cta_delay ) * 1000 );
    }

    destroy() {
        document.getElementById( 'ExperiencePage_entry_close').addEventListener( 'click', this.close );
        instance = null;
    }
}