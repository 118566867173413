import * as THREE from 'three';
import { Water } from 'three/examples/jsm/objects/Water.js';

import Experience from '../Experience.js';

export default class Sea {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('sea');
        }

        this.setGeometry();
        this.add();
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry( 10000, 10000 );
    }

    add() {
        const resource = this.resources.items[ 'water-normal' ];
        resource.wrapS = THREE.RepeatWrapping;
        resource.wrapT = THREE.RepeatWrapping;
        this.water = new Water(
            this.geometry,
            {
                textureWidth: 512,
                textureHeight: 512,
                waterNormals: resource,
                sunDirection: new THREE.Vector3( 3, 4, -4 ),
                sunColor: 0xaaaaaa,
                waterColor: 0x001e5f,
                distortionScale: 0.125,
                fog: true
            }
        );

        // this.water.position.y = 0.05;
        this.water.position.y = 0.075;
        this.water.material.uniforms.size.value = 10000;
        this.water.rotation.x = - Math.PI / 2;
        this.water.receiveShadow = true;
        this.experience.scene.add( this.water );

        if (this.debug.active) {
            const waterUniforms = this.water.material.uniforms;
            this.debugFolder.add( waterUniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'Distortion Scale' );
            this.debugFolder.add( waterUniforms.size, 'value', 0.1, 10000, 0.1 ).name( 'Size' );
        }
    }

    update() {
        this.water.material.uniforms[ 'time' ].value += 1.0 / 60.0;
    }
}