/** Libraries */
import * as THREE from 'three';
import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js';

/** Classes */
import Experience from '../../Experience.js';

/** Services */

// Instances of scenery
export default class Grass {
    constructor( object ) {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.blade = this.resources.items.grass.scene;
        this.grass = null;

        this.initModel( object );
    }

    initModel( object ) {
        const numBlades = 10000;
        const sampler = new MeshSurfaceSampler( object )
            .build();

        let objectScale = new THREE.Vector3();
        let objectPosition = new THREE.Vector3();
        let quaternion = new THREE.Quaternion();
        object.getWorldScale( objectScale );
        object.getWorldPosition( objectPosition );
        object.getWorldQuaternion( quaternion );


        const geometry = this.blade.children[ 0 ].geometry;
        const material = this.blade.children[ 0 ].material;

        this.grass = new THREE.InstancedMesh( geometry, material, numBlades );
        this.grass.applyQuaternion( quaternion );
        this.grass.position.set( objectPosition.x, objectPosition.y, objectPosition.z );

        const empty = new THREE.Object3D();
        const position = new THREE.Vector3();

        for ( let i = 0; i < numBlades; i++ ) {
            sampler.sample( position );

            const newPosition = new THREE.Vector3(
                ( position.x * objectScale.x ),
                ( position.y * objectScale.y ),
                ( position.z * objectScale.z ),
            )

            empty.position.copy( newPosition );
            empty.scale.set( 0.01, 0.01, 0.01 );
            empty.rotateX( Math.random() * 0.1 - 0.05 );
            empty.rotateY( Math.random() * 0.1 - 0.05 );


            empty.updateMatrix();

            this.grass.setMatrixAt( i, empty.matrix );
        }

        this.grass.instanceMatrix.needsUpdate = true;
        this.scene.add( this.grass );
    }

    update() {

    }

    destroy() {

    }
}