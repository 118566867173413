/** Libraries */
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

/** Classes */
import Experience from '../Experience.js';

/** Services */
import Content from '../../Services/Content.js';


export default class Scenery {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        // Resource
        this.models = {};
        this.animations = {};
        this.active = [];
        this.sceneChanges = [];

        this.manualAnimations = [];
        this.mixer = {};

        // this.initModels();
    }

    // initModels() {
    //     const content = Content.datasources.scenery.datasource_entries;
    //     for ( let i = 0; i < content.length; i++ ) {
    //         this.models[ content[ i ].value ] = this.resources.items[ content[ i ].value ].scene;
    //         // Set animations inside the object
    //         this.models[ content[ i ].value ].animations = this.resources.items[ content[ i ].value ].animations;
    //         this.models[ content[ i ].value ].traverse( ( child ) => {
    //             if ( child instanceof THREE.Mesh ) {
    //                 child.castShadow = true;
    //                 child.receiveShadow = true;
    //             }
    //         })
    //     }
    // }

    initAnimations( model, data ) {
        const animations = model.animations;

        if ( animations.length ) {
            this.mixer[ data.name ] = new THREE.AnimationMixer( model );
            this.mixer[ data.name ].loop = true;

            for ( let i = 0; i < animations.length; i++ ) {
                const item = this.mixer[ data.name ].clipAction( animations[ i ] );
                item.play();
            }
        }
    }

    applyManualAnimations( ) {
        // Loop through each model that needs to be animated
        for ( let i = 0; i < this.manualAnimations.length; i++ ) {
            
            const next = this.manualAnimations[ i ];
            const object = next.model.getObjectByName( next.data.object_name );
            const currentValue = object[ next.data.transform_type ][ next.data.apply_movement ];
            let newValue = next.reverse ? currentValue - parseFloat( next.data.speed ) : currentValue + parseFloat( next.data.speed );

            if ( newValue > parseFloat( next.data.upper_limit ) ) {
                if ( next.data.yoyo ) {
                    next.reverse = true;
                } else {
                    newValue = parseFloat( next.data.lower_limit );
                }
            }
            if ( newValue < parseFloat( next.data.lower_limit ) ) next.reverse = false;

            object[ next.data.transform_type ][ next.data.apply_movement ] = newValue;
        }

    }

    enterScene( model, item, instant ) {
        // Scale we want the scenery to be
        const scale = { x: parseFloat( item.scale[ 0 ].x ), y: parseFloat( item.scale[ 0 ].y ), z: parseFloat( item.scale[ 0 ].z ) };

        if ( instant ) {
            model.visible = true;
            model.scale.set( scale.x, scale.y, scale.z );
            this.initAnimations( model, item );

            for ( let i = 0; i < item.manual_animation.length; i++ ) {
                this.manualAnimations.push( { name: item.name, model, data: item.manual_animation[ i ], reverse: false } );
            }
        } else {
            let finished = false;
            const animation = new TWEEN.Tween( model.scale )
                .to( { x: scale.x, y: scale.y, z: scale.z }, parseFloat( item.appear_duration ) * 1000 )
                .easing( TWEEN.Easing.Elastic.Out )
                .delay( parseFloat( item.appear_at ) * 1000 )
                .onStart( () => model.visible = true )
                .start()
                .onComplete( () => {
                    this.initAnimations( model, item );

                    for ( let i = 0; i < item.manual_animation.length; i++ ) {
                        this.manualAnimations.push( { name: item.name, model, data: item.manual_animation[ i ], reverse: false } );
                    }
                    finished = true;
                } )

            let manuallyComplete = () => {
                model.scale.set( scale.x, scale.y, scale.z )
                animation.stop();
                TWEEN.remove( animation );
            };

            this.sceneChanges.push( { animation, finished, manuallyComplete } );
        }
    }
    
    leaveScene( model, item, instant ) {
        const scale = { x: 0, y: 0, z: 0 };

        let destroyModel = () => {
            // Remove from Manual Animations
            const index = this.manualAnimations.findIndex( ( anim ) => anim.name === item.name );
            if ( index > -1 ) this.manualAnimations.splice( index, 1 );

            // Remove from Animations
            if ( this.mixer[ item.name ] ) {
                this.mixer[ item.name ].stopAllAction();
                delete this.mixer[ item.name ];
            }

            this.scene.remove( model );
        };

        if ( instant ) {
            model.visible = false;
            destroyModel();
        } else {
            let finished = false;
            const animation = new TWEEN.Tween( model.scale )
                .to( { x: scale.x, y: scale.y, z: scale.z }, parseFloat( item.leave_duration ) * 1000 )
                .easing( TWEEN.Easing.Elastic.Out )
                .delay( parseFloat( item.leave_delay ) * 1000 )
                .onStart( () => model.visible = true )
                .start()
                .onComplete( () => {
                    model.visible = false;
                    destroyModel();
                    finished = true;
                } )

            let manuallyComplete = () => {
                model.scale.set( scale.x, scale.y, scale.z );
                model.visible = false;
                animation.stop();
                TWEEN.remove( animation );
                destroyModel();
            };

            this.sceneChanges.push( { animation, finished, manuallyComplete } );
        }
    }

    onStateUpdate( instant ) {

        // Complete the scene changes here
        this.sceneChanges.forEach( ( change ) => {
            if ( !change.finished ) change.manuallyComplete();
        } )
        this.sceneChanges = [];

        // Remove scenery no longer in the state
        for ( let i = 0; i < this.active.length; ) {
            const match = this.experience.state.scenery.find( ( item ) => item.name === this.active[ i ].item.name );
            if ( !match ) {
                this.leaveScene( this.active[ i ].model, this.active[ i ].item, instant );
                this.active.splice( i, 1 );

                if ( this.debug.active ) {
                    this.debug.ui.removeFolder( this.active[ i ].item.name ).close();
                }
            } else {
                i++;
            }
        }

        for ( let i = 0; i < this.experience.state.scenery.length; i++ ) {
            
            const match = this.active.find( ( next ) => next.item.name === this.experience.state.scenery[ i ].name );
            if ( !match ) {
            
                const item = this.experience.state.scenery[ i ];
                const model = this.resources.items[ item.model ].scene.clone();
                // Set animations inside the object
                model.animations = this.resources.items[ item.model ].animations;
                model.traverse( ( child ) => {
                    if ( child instanceof THREE.Mesh ) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                })

                // Set to 0 so we can animate in
                model.scale.set( 0, 0, 0 );
                model.position.set( parseFloat( item.position[ 0 ].x ), parseFloat( item.position[ 0 ].y ), parseFloat( item.position[ 0 ].z ) );
                model.rotation.set( parseFloat( item.rotation[ 0 ].x ), parseFloat( item.rotation[ 0 ].y ), parseFloat( item.rotation[ 0 ].z ) );
                model.visible = false;
                
                this.scene.add( model );
                this.enterScene( model, item, instant );

                this.active.push( { model, item } );
            
            }

            if ( this.debug.active ) {
                const debugFolder = this.debug.ui.addFolder( item.name ).close();
                debugFolder.add( model.position, 'x', -500, 500, 0.01 ).name( 'Position X' );
                debugFolder.add( model.position, 'y', -500, 500, 0.01 ).name( 'Position Y' );
                debugFolder.add( model.position, 'z', -500, 500, 0.01 ).name( 'Position Z' );
                debugFolder.add( model.scale, 'x', 0, 10, 0.001 ).name( 'Scale X' );
                debugFolder.add( model.scale, 'y', 0, 10, 0.001 ).name( 'Scale Y' );
                debugFolder.add( model.scale, 'z', 0, 10, 0.001 ).name( 'Scale Z' );
                debugFolder.add( model.rotation, 'x', -6.6, 6.6, 0.01 ).name( 'Rotation X' );
                debugFolder.add( model.rotation, 'y', -6.6, 6.6, 0.01 ).name( 'Rotation Y' );
                debugFolder.add( model.rotation, 'z', -6.6, 6.6, 0.01 ).name( 'Rotation Z' );
            }
        }

        // console.log( 'active to check if geometries and materials are the same', [ ...this.active ] );

    }

    update() {
        this.applyManualAnimations();

        const values = Object.keys( this.mixer );
        if ( values.length ) {
            for ( let i = 0; i < values.length; i++ ) {
                const speed = 0.001;
                this.mixer[ values[ i ] ].update( this.time.delta * speed );
            }
        }
    }

    destroy() {
        // this.mixer.stopAllAction();
        // this.animations.forEach( ( animation ) => this.mixer.uncacheClip( animation ) );
        // this.animations = [];
    }
}
