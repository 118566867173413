import Content from './Content.js';

const privates = {
    entries: {},
    simple: false,
    currentId: null,
    cameraOffset: false,
    menu: false,
    loading: false
}

class State {

    constructor() {
        this.listeners = [];
    }

    get simple () {
        return privates.simple;
    }

    set simple ( val ) {
        privates.simple = val;
    }

    get entries () {
        return privates.entries;
    }

    set entries ( val ) {
        privates.entries = val;
    }

    get currentStatus ( ) {
        return this.entries[ this.currentId ];
    }

    get currentId ( ) {
        return privates.currentId;
    }

    set currentId ( val ) {
        privates.currentId = val;
        this.loading = true;

        if ( !this.simple ) {
            this.sendToListeners( 'initiate-diary', val );
        } else {
            this.stateChangeReady();
        }
    }

    set cameraOffset ( val ) {
        privates.cameraOffset = val;
        this.sendToListeners( 'camera-offset', val );
    }

    get cameraOffset () {
        return privates.cameraOffset;
    }

    set menu( val ) {
        privates.menu = val;
        this.sendToListeners( 'menu', val );
    }

    get menu() {
        return privates.menu;
    }

    set loading( val ) {
        privates.loading = val;
        this.sendToListeners( 'loading', val );
    }

    get loading() {
        return privates.loading;
    }

    stateInitialReady() {
        this.sendToListeners( 'bootup', false );
        this.sendToListeners( 'diary', this.currentId );
    }
    
    stateChangeReady() {
        this.loading = false;
        this.sendToListeners( 'diary', this.currentId );
    }

    stateHolding() {
        setTimeout( () => this.sendToListeners( 'bootup', false ) );
    }

    changeEntryStatus( id, status ) {
        this.entries[ id ] = status;
        localStorage.setItem( 'entry-status', JSON.stringify( this.entries ) );
        this.sendToListeners( 'entry-status', { id, status } );
    }

    // Call each listener with the given data
    sendToListeners( data, val ) {
        for( let i = 0; i < this.listeners.length; i++ ) {
            this.listeners[ i ]( data, val );
        }
    }

    // Add an external listener for when a WebSocket Message is received
    addListener( func ) {
        this.listeners.push( func );
    }

    // Remove an external listener
    removeListener( func ) {
        for( let i = 0; i < this.listeners.length; i++ ) {
            if ( this.listeners[ i ] === func ) {
                this.listeners.splice( i, 1 );
            }
        }
    }

    findLatest( entryIds ) {
        const active = entryIds.find( ( entry ) => this.entries[ entry ] === 'active' );
        const pending = entryIds.find( ( entry ) => this.entries[ entry ] === 'pending' );
        const unknown = entryIds.find( ( entry ) => !this.entries[ entry ] );
        return active || pending || unknown || entryIds[ entryIds.length - 1 ];
    }

    onInit( simple ) {
        this.simple = simple;
        const storage = localStorage.getItem( 'entry-status' );
        this.entries = storage ? JSON.parse( storage ) : {};
        const entryIds = Content.getDiaryEntryIds();
        this.currentId = this.findLatest( entryIds );  
    }
}


export default new State();