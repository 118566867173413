/** Classes */
import Content from "../../Services/Content.js";
import State from "../../Services/State.js";

export default class DiaryList {
    constructor( _element, callback ) {
        /** Init */
        this.element = _element;
        this.callback = callback;
        this.list = null;
        this.items = [];
        this.timer = null;
        
        /** Bindings */
        this.stateUpdate = this.stateUpdate.bind( this );

        this.onInit();
    }

    select( entryId ) {
        State.currentId = entryId;
        if ( this.callback ) this.callback();
    }

    stateUpdate ( event, data ) {
        if ( event === 'entry-status' ) {
            const element = this.items.find( ( item ) => item.dataId === data.id );
            if ( element ) {
                element.classList.remove( 'pending', 'active', 'completed' );
                element.classList.add( data.status );
            }
        }

        if ( event === 'diary' ) {
            this.items.forEach( ( item ) => {
                if ( item.dataId === State.currentId ) {
                    item.classList.add( 'highlight' );
                } else {
                    item.classList.remove( 'highlight' );
                }
            } )
        }
    }

    onInit() {
        this.element.classList.add( 'DiaryList' );
        this.element.innerHTML = `
            <h3 class="diary-list-title title-s">See More Days</h3>
        `;
        this.list = document.createElement( 'div' );
        this.list.className = 'list';
        this.element.appendChild( this.list );

        const entries = Content.getDiaryEntries();
        let destination = null;
        let currentGroup = null;

        this.items = entries.map( ( entry ) => {
            if ( entry.country && entry.country !== destination ) {
                destination = entry.country;
                currentGroup = document.createElement( 'details' );
                currentGroup.className = 'group';
                currentGroup.innerHTML = `<summary class="summary">
                    <h4 class="summary-title title-xxs">${ entry.country }</h4>
                </summary>`;
                currentGroup.setAttribute( 'open', true );
                this.list.appendChild( currentGroup );
            }

            const item = document.createElement( 'div' );
            const status = State.entries[ entry._uid ];
            item.dataId = entry._uid;
            item.className = `item ${ status || '' } ${ State.currentId === entry._uid ? 'highlight' : '' }`;
            item.onclick = () => this.select( entry._uid );
            item.innerHTML = `
                <p class="name">${ entry.name }</p>
            `;
            currentGroup.appendChild( item );

            return item;
        })

        const active = this.items.find( ( item ) => State.currentId === item.dataId );
        if ( active ) this.timer = setTimeout( () => active.scrollIntoView( { behavior: 'smooth', block: 'nearest' } ), 500 );

        State.addListener( this.stateUpdate );
    }
}