export default class Carousel {
    constructor( _element, data ) {

        /** Variables */
        this.slide = 0;
        this.imagesLoaded = 0;
        this.data = data;

        this.previous = this.previous.bind( this );
        this.next = this.next.bind( this );
        this.loaded = this.loaded.bind( this );

        /** Init */
        this.element = _element;
        this.wrapper = null;
        this.slides = [];

        this.create();
    }

    updateSlides() {
        this.slides.forEach( ( slide, index ) => {
            slide.classList.remove( 'inactive' );

            if ( index !== this.slide ) {
                slide.classList.add( 'inactive' );
            } else {
                slide.classList.add( 'active' );
            }
        } )

        const transform = this.slides.reduce( ( amount, next, index ) => {
            if ( index < this.slide ) amount += next.offsetWidth;
            if ( index === this.slide ) amount += ( next.offsetWidth / 2 );

            return amount;
        }, 0 );

        this.wrapper.style.transform = `translateX( ${ -transform }px )`;
    }

    previous() {
        this.slide = this.slide > 0 ? this.slide - 1 : 0;
        this.updateSlides();
    }

    next() {
        this.slide = this.slide < this.slides.length - 1 ? this.slide + 1 : this.slides.length - 1;
        this.updateSlides();
    }

    addItemArrows( element, left, right ) {
        if ( left ) {
            const arrowLeft = document.createElement( 'button' );
            arrowLeft.type = 'button';
            arrowLeft.className = 'carousel-arrow left';
            arrowLeft.innerHTML = `
                <img class='icon active' src='/images/carousel/left/blue.svg' draggable='false' />
            `;
            arrowLeft.onclick = this.previous;
            element.appendChild( arrowLeft );
        }

        if ( right ) {
            const arrowRight = document.createElement( 'button' );
            arrowRight.type = 'button';
            arrowRight.className = 'carousel-arrow right';
            arrowRight.innerHTML = `
                <img class='icon active' src='/images/carousel/right/blue.svg' draggable='false' />
            `;
            arrowRight.onclick = this.next;

            element.appendChild( arrowRight );
        }
    }

    addUI() {
        this.slides.forEach( ( slide, index ) => this.addItemArrows( slide, index !== 0, index !== this.slides.length - 1 ) );

        if ( this.slides.length ) {
            this.wrapper.style.transform = `translateX( ${ - ( this.slides[ 0 ].offsetWidth / 2 ) }px )`;
        }
    }

    loaded() {
        this.imagesLoaded++;
        // Check if finished loading
        if ( this.imagesLoaded === this.data.length ) this.addUI();
    }

    /** Set up the initial carousel */
    create() {
        this.element.classList.add( 'Carousel' );

        /** Wrapper */
        this.wrapper = document.createElement( 'div' );
        this.wrapper.classList.add( 'carousel-wrapper' );
        this.element.appendChild( this.wrapper );

        /** Slides */
        this.slides = this.data.map( ( slide, index ) => {
            const element = document.createElement( 'div' );
            element.className = `Carousel-asset ${ index === 0 ? 'active' : 'inactive' }`;
            element.style.maxWidth = `${ this.element.offsetWidth }px`;
            const image = document.createElement( 'img' );
            image.className = 'image';
            image.onload = this.loaded;
            image.onerror = this.loaded;
            image.src = slide.asset.filename;

            element.appendChild( image );
            this.wrapper.appendChild( element );

            return element;
        } )
    }

    destroy() {}
}