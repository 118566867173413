/** Services */
import State from "../../Services/State.js";

/** Components */

/** Variables */
let instance = null;

export default class Navigation {

    constructor( _element, callback, data, previous, next, instant ) {
        // Singleton
        if ( instance ) return instance;
        instance = this;

        this.element = _element;
        this.callback = callback;
        this.data = data;
        this.previous = previous;
        this.next = next;
        this.delay = null;

        this.onInit( instant );
    }

    create() {
        this.element.className = 'Navigation transform-in';
        this.element.innerHTML = `
            ${ this.callback ? `
                <button id="Navigation-explore" type="button" class="button-primary white ${ State.currentStatus === 'completed' ? 'completed' : '' }">
                    ${ this.data.cta_text }
                    <div class="split"></div>
                </button>
            ` : '' }

            ${ this.previous ? `
                <button id="Navigation-previous" type="button" class="button-primary size-small white">
                    <span class="text-s">Previous:</span>
                    <span class="text-xs">${ this.previous.title }</span>
                    <div class="split"></div>
                </button>
            ` : '' }

            ${ this.next ? `
                <button id="Navigation-next" type="button" class="button-primary size-small white">
                    <span class="text-s">Next Up:</span>
                    <span class="text-xs">${ this.next.title }</span>
                    <div class="split"></div>
                </button>
            ` : '' }
        `;

        const explore = document.getElementById( 'Navigation-explore' );
        if ( explore ) explore.addEventListener( 'click', this.callback );

        const previous = document.getElementById( 'Navigation-previous' );
        if ( previous ) previous.addEventListener( 'click', this.previous );

        const next = document.getElementById( 'Navigation-next' );
        if ( next ) next.addEventListener( 'click', this.next );

        setTimeout( () => this.element.classList.remove( 'transform-in' ) );
    }

    onInit( instant ) {
        this.delay = setTimeout( () => this.create(), instant ? 0 : parseFloat( this.data.cta_delay ) * 1000 );
    }

    destroy() {
        instance = null;
        
        const explore = document.getElementById( 'Navigation-explore' );
        if ( explore ) explore.removeEventListener( 'click', this.callback );

        const previous = document.getElementById( 'Navigation-previous' );
        if ( previous ) previous.removeEventListener( 'click', this.previous );

        const next = document.getElementById( 'Navigation-next' );
        if ( next ) next.removeEventListener( 'click', this.next );

        clearTimeout( this.delay );
    }
}