/** Services */
import StateService from "../Services/State.js";

/** Components */
import Bootup from './Loading/Bootup.js';
import Loading from './Loading/Loading.js';
import HoldingPage from "./Loading/Holding.js";

import SimplePage from "./Simple/Page.js";
import ExperiencePage from "./Experience/Page.js";

// Controls all the elements that are on the screen
class UI {
    constructor( _element ) {

        /** Bindings */
        this.stateUpdate = this.stateUpdate.bind( this );

        /** Elements */
        this.element = _element;

        // Bootup
        this.bootup = null;
        this.bootupElement = null;

        // Loading
        this.loading = null;
        this.loadingElement = null;

        // Main UI element
        this.main = null;
        this.mainElement = null;

        this.onInit();
    }

    /** Show the loading screen */
    showBootup() {
        if ( !this.bootupElement ) {
            this.bootupElement = document.createElement( 'div' );
            this.element.appendChild( this.bootupElement );
            this.bootup = new Bootup( this.bootupElement );
        }
    }

    /** Hide the loading screen */
    hideBootup() {
        if ( this.bootup ) this.bootup.onClose();
        setTimeout( () => {
            if ( this.bootup ) this.bootup.destroy();
            if ( this.bootupElement ) this.bootupElement.remove();
            this.bootup = null;
            this.bootupElement = null;
        }, 1000 )
    }

    /** Show the loading screen */
    showLoading() {
        if ( !this.loadingElement ) {
            this.loadingElement = document.createElement( 'div' );
            this.element.appendChild( this.loadingElement );
            this.loading = new Loading( this.loadingElement );
        }
    }

    /** Hide the loading screen */
    hideLoading() {
        if ( this.loading ) this.loading.destroy();
        if ( this.loadingElement ) this.loadingElement.remove();
        this.loading = null;
        this.loadingElement = null;
    }

    /** Adds the main content block */
    addMain() {
        if ( !this.mainElement ) {
            this.mainElement = document.createElement( 'div' );
            this.element.appendChild( this.mainElement );
            this.main = StateService.simple ? new SimplePage( this.mainElement ) : new ExperiencePage( this.mainElement );
        }
    }

    addHolding() {
        if ( !this.holdingElement ) {
            this.holdingElement = document.createElement( 'div' );
            this.element.appendChild( this.holdingElement );
            this.holding = new HoldingPage( this.holdingElement );
        }
    }

    /** Listener gets called when the state in the application, update and show the corresponding screens */
    stateUpdate( event, data ) {
        switch( event ) {
            case 'bootup':
                return data ? this.showBootup() : this.hideBootup();
            case 'loading':
                return data ? this.showLoading() : this.hideLoading();
            case 'diary':
                this.main.entryUpdate( data );
                return;
        }
    }

    onHolding() {
        this.addHolding();
        StateService.addListener( this.stateUpdate );
    }

    onReady() {
        this.addMain();
        StateService.addListener( this.stateUpdate );
    }

    onInit() {
        this.element.className = 'Main';
        this.showBootup();
    }
}

export default UI;