/** Libraries */
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

/** Classes */
import Experience from '../Experience.js';

/** Services */
import Content from '../../Services/Content.js';


export default class Tracker {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        // Resource
        this.group = null;
        this.models = {};
        this.animations = null;
        this.mixer = null;

        this.updating = [];

        this.initGroup();
        // this.initModels();
        this.initAnimations();
        this.addDebug();
    }

    addDebug() {
        if ( this.debug.active ) {
            // Folders
            const debugFolder = this.debug.ui.addFolder('Tracker Settings').close();
            const debugPositionFolder = debugFolder.addFolder('Position Settings').close();

            // Position settings
            debugPositionFolder.add( this.group.position, 'x', -500, 500, 0.01 ).name( 'Tracker Position X' );
            debugPositionFolder.add( this.group.position, 'y', 0, 200, 0.01 ).name( 'Tracker Position Y' );
            debugPositionFolder.add( this.group.position, 'z', -500, 500, 0.01 ).name( 'Tracker Position Z' );
        }
    }

    initGroup() {
        this.group = new THREE.Group();
        this.group.name = 'tracker';
        this.scene.add( this.group );
    }

    initModels() {
        const content = Content.datasources.trackers.datasource_entries;
        for ( let i = 0; i < content.length; i++ ) {
            if ( content[ i ].value !== 'none' ) {
                this.models[ content[ i ].value ] = this.resources.items[ content[ i ].value ].scene;
                // Set animations inside the object
                this.models[ content[ i ].value ].animations = this.resources.items[ content[ i ].value ].animations;
                this.models[ content[ i ].value ].traverse( ( child ) => {
                    if ( child instanceof THREE.Mesh ) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                })

                this.models[ content[ i ].value ].visible = false;

                this.group.add( this.models[ content[ i ].value ] );
            }
        }
    }



    initAnimations() {
        // this.animations = this.resources.items.site.animations.filter( ( anim ) => anim.name.includes( 'Anim_' ) );
        // this.mixer = new THREE.AnimationMixer( this.model );
        // this.mixer.loop = true;
        
        // for ( let i = 0; i < this.animations.length; i++ ) {
        //     const item = this.mixer.clipAction( this.animations[ i ] );
        //     item.play();
        // }
    }

    // Move camera to correct location and set limits
    completedTimeline( instant ) {
        const camera = this.experience.state.camera[ 0 ];
        const position = { x: parseFloat( camera.tracker[ 0 ].x ), y: parseFloat( camera.tracker[ 0 ].y ), z: parseFloat( camera.tracker[ 0 ].z ) };

        if ( instant ) {
            this.group.position.set( position.x, position.y, position.z );
            const keys = Object.keys( this.models );
            for ( let i = 0; i < keys.length; i++ ) {
                this.models[ keys[ i ] ].visible = false;
            }
        } else {
            const animation = new TWEEN.Tween( this.group.position )
                .to( { x: position.x, y: position.y, z: position.z }, parseFloat( camera.transition ) * 1000 )
                .easing( TWEEN.Easing.Quadratic.InOut )
                .start()

            this.updating.push( animation );
        }
    }


    applyTimelineSegments( remaining ) {

        // Timeline segment data
        const segment = remaining[ 0 ];

        if ( !segment ) return this.completedTimeline();

        // Hide current tracker model
        const keys = Object.keys( this.models );
        for ( let i = 0; i < keys.length; i++ ) {
            this.models[ keys[ i ] ].visible = false;
        }

        // Show new model
        if ( segment.model_type !== 'none' ) this.models[ segment.model_type ].visible = true;

        // Move the target to the correct place
        if ( segment.target && segment.target.length ) {
            const points = segment.target
                .map( ( item ) => new THREE.Vector3( parseFloat( item.x ), parseFloat( item.y ), parseFloat( item.z ) ) );

            const curve = new THREE.CatmullRomCurve3( points );
            const time = { current: 0, duration: segment.target_duration * 1000 }

            // TODO: ADD CALLBACK SO KNOW WHEN BOTH OF THE TWO TWEENS HAVE FINISHED
            const animation = new TWEEN.Tween( time )
                .to( { current: time.duration }, time.duration )
                .easing( TWEEN.Easing.Linear.None )
                .start()
                .onUpdate( () => {
                    const positionOnCurve = ( time.current % time.duration ) / time.duration;
                    const position = curve.getPointAt( positionOnCurve );
                    const tangent = ( curve.getTangentAt( positionOnCurve ) ).normalize();
                    this.group.position.copy( position );
                    this.group.lookAt( position.clone().add( tangent ) );
                } )
                .onComplete( () => {
                    this.group.position.set( 
                        parseFloat( segment.target[ segment.target.length - 1 ].x ),
                        parseFloat( segment.target[ segment.target.length - 1 ].y ),
                        parseFloat( segment.target[ segment.target.length - 1 ].z )
                    )

                    remaining.shift();
                    return remaining.length ? this.applyTimelineSegments( remaining ) : this.completedTimeline();

                } )
            this.updating.push( animation );
        }

        // Scale model
        if ( segment.model_scale && segment.model_scale.length ) {
            const points = segment.model_scale
                .map( ( item ) => new THREE.Vector3( parseFloat( item.x ), parseFloat( item.y ), parseFloat( item.z ) ) );
            const curve = new THREE.CatmullRomCurve3( points );
            const time = { current: 0, duration: segment.model_duration * 1000 }

            const animation = new TWEEN.Tween( time )
                .to( { current: time.duration }, time.duration )
                .easing( TWEEN.Easing.Quadratic.InOut )
                .start()
                .onUpdate( () => {
                    const positionOnCurve = ( time.current % time.duration ) / time.duration;
                    const position = curve.getPointAt( positionOnCurve );
                    this.group.scale.copy( position );
                } )
                .onComplete( () => {
                    this.group.scale.set( 
                        parseFloat( segment.model_scale[ segment.model_scale.length - 1 ].x ),
                        parseFloat( segment.model_scale[ segment.model_scale.length - 1 ].y ),
                        parseFloat( segment.model_scale[ segment.model_scale.length - 1 ].z )
                    )
                } )
            this.updating.push( animation );

        }
    }

    addToScene( keys ) {
        for ( let i = 0; i < keys.length; i++ ) {
            if ( keys[ i ] !== 'none' ) {
                this.models[ keys[ i ] ] = this.resources.items[ keys[ i ] ].scene;
                // Set animations inside the object
                this.models[ keys[ i ] ].animations = this.resources.items[ keys[ i ] ].animations;
                this.models[ keys[ i ] ].traverse( ( child ) => {
                    if ( child instanceof THREE.Mesh ) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                })

                this.models[ keys[ i ] ].visible = false;

                this.group.add( this.models[ keys[ i ] ] );
            }
        }
    }

    removeFromScene( keys ) {
        for ( let i = 0; i < keys.length; i++ ) {
            if ( keys[ i ] !== 'none' ) {
                this.scene.remove( this.models[ keys[ i ] ] );
            }
        }
    }

    handleModels( timeline ) {
        const required = Array.from( new Set( timeline.map( ( item ) => item.model_type ) ) );
        const toAdd = required.filter( ( item ) => !this.models[ item ] );
        const toRemove = Object.keys( this.models ).filter( ( key ) => !required.includes( key ) );
        this.addToScene( toAdd );
        this.removeFromScene( toRemove );
    }

    onStateUpdate( instant ) {
        this.updating.forEach( ( animation ) => {
            animation.stop();
            TWEEN.remove( animation );
        } )
        this.updating = [];

        if ( !instant ) {
            // Handle Correct Models being added and removed from the scene here...
            this.handleModels( [ ...this.experience.state.timeline ] );
            this.applyTimelineSegments( [ ...this.experience.state.timeline ] );
        } else {
            this.completedTimeline( instant );
        }
    }

    update() {}

    destroy() {
        // this.mixer.stopAllAction();
        // this.animations.forEach( ( animation ) => this.mixer.uncacheClip( animation ) );
        // this.animations = [];
    }
}
