/** Libraries */
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

/** Classes */
import Experience from '../Experience.js';

/** Services */
import Content from '../../Services/Content.js';


export default class Scenery {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        // Resource
        this.models = {};
        this.active = [];
        this.sceneChanges = [];

        // this.initModels();
    }

    enterScene( model, item, data, instant ) {
        // console.log( 'ENTER SCENE model', model );
        // console.log( 'ENTER SCENE item', item );
        // console.log( 'ENTER SCENE instant', instant );
        // Scale we want the scenery to be
        const scale = { x: parseFloat( data.scale[ 0 ].x ), y: parseFloat( data.scale[ 0 ].y ), z: parseFloat( data.scale[ 0 ].z ) };

        if ( instant ) {
            model.visible = true;
            model.scale.set( scale.x, scale.y, scale.z );
        } else {
            let finished = false;
            const animation = new TWEEN.Tween( model.scale )
                .to( { x: scale.x, y: scale.y, z: scale.z }, parseFloat( item.appear_duration ) * 1000 )
                .easing( TWEEN.Easing.Elastic.Out )
                .delay( parseFloat( item.appear_at ) * 1000 )
                .onStart( () => model.visible = true )
                .start()
                .onComplete( () => {
                    finished = true;
                } )

            let manuallyComplete = () => {
                model.scale.set( scale.x, scale.y, scale.z )
                animation.stop();
                TWEEN.remove( animation );
            };

            this.sceneChanges.push( { animation, finished, manuallyComplete } );
        }

        // console.log( 'entering scene', item.name );
    }
    
    leaveScene( model, item, instant ) {
        // const scale = { x: 0, y: 0, z: 0 };

        // let destroyModel = () => {
        //     this.scene.remove( model );
        // };

        // if ( instant ) {
        //     model.visible = false;
        //     destroyModel();
        // } else {
        //     let finished = false;
        //     const animation = new TWEEN.Tween( model.scale )
        //         .to( { x: scale.x, y: scale.y, z: scale.z }, parseFloat( item.leave_duration ) * 1000 )
        //         .easing( TWEEN.Easing.Elastic.Out )
        //         .delay( parseFloat( item.leave_delay ) * 1000 )
        //         .onStart( () => model.visible = true )
        //         .start()
        //         .onComplete( () => {
        //             model.visible = false;
        //             destroyModel();
        //             finished = true;
        //         } )

        //     let manuallyComplete = () => {
        //         model.scale.set( scale.x, scale.y, scale.z );
        //         model.visible = false;
        //         animation.stop();
        //         TWEEN.remove( animation );
        //         destroyModel();
        //     };

        //     this.sceneChanges.push( { animation, finished, manuallyComplete } );
        // }

        // console.log( 'item leaving scene', item.name );
    }

    createCity( data ) {
        const group = new THREE.Group();
        group.position.set( parseFloat( data.position[ 0 ].x ), parseFloat( data.position[ 0 ].y ), parseFloat( data.position[ 0 ].z ) );
        group.scale.set( parseFloat( data.scale[ 0 ].x ), parseFloat( data.scale[ 0 ].y ), parseFloat( data.scale[ 0 ].z ) );
        group.rotation.set( parseFloat( data.rotation[ 0 ].x ), parseFloat( data.rotation[ 0 ].y ), parseFloat( data.rotation[ 0 ].z ) );

        for ( let i = 0; i < data.items.length; i++ ) {
            const item = data.items[ i ];
            // console.log( 'item', item );
            // console.log( 'resources', this.resources.items );
            const model = this.resources.items[ item.item ].scene;
            model.position.set( parseFloat( item.position[ 0 ].x ), parseFloat( item.position[ 0 ].y ), parseFloat( item.position[ 0 ].z ) )
            model.scale.set( parseFloat( item.scale[ 0 ].x ), parseFloat( item.scale[ 0 ].y ), parseFloat( item.scale[ 0 ].z ) )
            model.rotation.set( parseFloat( item.rotation[ 0 ].x ), parseFloat( item.rotation[ 0 ].y ), parseFloat( item.rotation[ 0 ].z ) )
            group.add( model );
        }

        return group;
    }
    
    onStateUpdate( instant ) {

        // Complete the scene changes here
        this.sceneChanges.forEach( ( change ) => {
            if ( !change.finished ) change.manuallyComplete();
        } )
        this.sceneChanges = [];

        // Remove city no longer in the state
        for ( let i = 0; i < this.active.length; ) {
            const match = this.experience.state.cities?.find( ( item ) => item.name === this.active[ i ].item.name );
            if ( !match ) {
                this.leaveScene( null, this.active[ i ].item, instant );
                // this.active.splice( i, 1 );

                // if ( this.debug.active ) {
                //     this.debug.ui.removeFolder( this.active[ i ].item.name ).close();
                // }
            } else {
                i++;
            }
        }

        if ( this.experience.state.cities ) {
            for ( let i = 0; i < this.experience.state.cities.length; i++ ) {
                
                const match = this.active.find( ( next ) => next.item.name === this.experience.state.cities[ i ].name );
                if ( !match ) {
                
                    // ADD CITY HERE
                    const item = this.experience.state.cities[ i ];
                    // console.log( 'ITEM', item );

                    const data = Content.getCityByName( item.name );
                    const city = this.createCity( data );

                    // const model = this.resources.items[ item.model ].scene.clone();
                    // Set animations inside the object
                    city.traverse( ( child ) => {
                        if ( child instanceof THREE.Mesh ) {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }
                    })

                    // Set to 0 so we can animate in
                    // city.scale.set( 0, 0, 0 );
                    city.visible = false;
                    
                    this.scene.add( city );
                    this.enterScene( city, item, data, instant );

                    this.active.push( { city, item } );
                
                }

                if ( this.debug.active ) {
                    const debugFolder = this.debug.ui.addFolder( item.name ).close();
                    debugFolder.add( model.position, 'x', -500, 500, 0.01 ).name( 'Position X' );
                    debugFolder.add( model.position, 'y', -500, 500, 0.01 ).name( 'Position Y' );
                    debugFolder.add( model.position, 'z', -500, 500, 0.01 ).name( 'Position Z' );
                    debugFolder.add( model.scale, 'x', 0, 10, 0.001 ).name( 'Scale X' );
                    debugFolder.add( model.scale, 'y', 0, 10, 0.001 ).name( 'Scale Y' );
                    debugFolder.add( model.scale, 'z', 0, 10, 0.001 ).name( 'Scale Z' );
                    debugFolder.add( model.rotation, 'x', -6.6, 6.6, 0.01 ).name( 'Rotation X' );
                    debugFolder.add( model.rotation, 'y', -6.6, 6.6, 0.01 ).name( 'Rotation Y' );
                    debugFolder.add( model.rotation, 'z', -6.6, 6.6, 0.01 ).name( 'Rotation Z' );
                }
            }
        }

        // console.log( 'active to check if geometries and materials are the same', [ ...this.active ] );

    }

    update() {

    }

    destroy() {

    }
}
