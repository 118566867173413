/** Libraries */
import * as THREE from 'three';

/** Classes */
import Experience from '../Experience.js';

/** Objects */
import Cities from './Cities.js';
import Lights from './Lights.js';
import Model from './Model.js';
import Scenery from './Scenery.js';
import Tracker from './Tracker.js';
import Sea from './Sea.js';
// import Sea from './Sea-LowPoly.js';
// import Flag from './Flag.js';
// import Grass from './Grass/New/NewMesh.js';

export default class World {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
    }

    onInit() {
        this.sea = new Sea();
        this.lights = new Lights();
        this.model = new Model();
        this.tracker = new Tracker();
        this.scenery = new Scenery();
        this.cities = new Cities();
    }
    
    addGround() {
        const geometry = new THREE.BoxGeometry( 1000, 1000, 3 );
        const material = new THREE.MeshStandardMaterial( { color: 0x9a7783 } );
        const mesh = new THREE.Mesh( geometry, material );
        mesh.rotateX( -Math.PI / 2 );
        mesh.receiveShadow = true;
        mesh.position.y = -1.5;
        this.scene.add( mesh );
    }

    onStateUpdate( instant ) {
        this.lights.onStateUpdate( instant );
        this.tracker.onStateUpdate( instant );
        this.scenery.onStateUpdate( instant );
        this.cities.onStateUpdate( instant );
        this.model.onStateUpdate( instant );
    }

    update() {
        if ( this.tracker ) this.tracker.update();
        if ( this.scenery ) this.scenery.update();
        if ( this.sea ) this.sea.update();
        if ( this.model ) this.model.update();
            // this.flag.update();
    }
}