/** Libraries */
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

/** Classes */
import EventEmitter from './EventEmitter.js';

export default class Resources extends EventEmitter {

    constructor( sources ) {
        super();

        this.sources = sources;

        this.requiredAssets = [];
        this.items = {};
        // this.toLoad = this.sources.length;
        // this.loaded = 0;

        this.setLoaders();
        // this.startLoading();
        // this.trigger( 'ready' );
    }

    setLoaders() {
        this.loaders = {};
        this.loaders.gltfLoader = new GLTFLoader();
        this.loaders.textureLoader = new THREE.TextureLoader();
        
        // this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
    }


    /** When we change to a new state work out what objects we need and what we can remove */
    async handleStateChange( required ) {
        const keys = Object.keys( this.items );
        const toRemove = keys.filter( ( key ) => !required.find( ( item ) => item === key ) );
        const toAdd = required.filter( ( key ) => !this.items[ key ] );
        this.requiredAssets = toAdd;

        // console.log( 'loading assets' );
        await this.loadAssets( toAdd );
        // console.log( 'completed assets' )

        this.removeAssets( toRemove );
        // console.log( 'removed assets' );
    }

    /** Loads the required assets that the scene needs */
    async loadAssets( keys ) {
        return new Promise( ( resolve, reject ) => {

            // console.log( 'load assetssss', keys, this.sources );
            const sources = keys.map( ( key ) => this.sources[ key ] ).filter( ( source ) => source );
            // console.log( 'sourcess....', sources );

            if ( sources.length === 0 ) return resolve();

            this.toLoad = sources.length;
            this.loaded = 0;

            const sourceLoaded = ( name, file ) => {
                this.items[ name ] = file;
                this.loaded++;
                // console.log( 'loaded a source' );
        
                if ( this.loaded === this.toLoad ) {
                    resolve();
                }
            }

            // Load each source
            sources.forEach( ( source ) => {
                switch( source.type ) {
                    case 'gltfModel': 
                        this.loaders.gltfLoader.load( source.path, ( file ) => {
                            sourceLoaded( source.name, file );
                        });
                        break;
                    case 'texture':
                        this.loaders.textureLoader.load( source.path, ( file ) => {
                            sourceLoaded( source.name, file );
                        });
                        break;
                }
            });
        } )
    }

    removeAsset( key ) {
        if ( !this.requiredAssets.includes( key ) ) {
            const item = this.items[ key ];
            // Check if texture, otherwise its a model
            if ( item.isTexture ) {
                item.dispose();
            } else {
                item.scene.traverse( ( child ) => {
                    // Test if it's a mesh
                    if ( child instanceof THREE.Mesh ) {
                        child.geometry.dispose();
        
                        // Loop through the material properties
                        for ( const key in child.material ) {
                            const value = child.material[ key ];
        
                            // Test if there is a dispose function
                            if ( value && typeof value.dispose === 'function' ) {
                                value.dispose();
                            }
                        }
                    }
                } );
            }

            delete this.items[ key ];
        }
    }
    
    /** Remove all assets that aren't needed from the scene */
    removeAssets( keys ) {
        keys.forEach( ( key ) => {
            const item = this.items[ key ];
            // Check if texture, otherwise its a model
            if ( item.isTexture ) {
                item.dispose();
            } else {
                item.scene.traverse( ( child ) => {
                    // Test if it's a mesh
                    if ( child instanceof THREE.Mesh ) {
                        child.geometry.dispose();
        
                        // Loop through the material properties
                        for ( const key in child.material ) {
                            const value = child.material[ key ];
        
                            // Test if there is a dispose function
                            if ( value && typeof value.dispose === 'function' ) {
                                value.dispose();
                            }
                        }
                    }
                } );
            }

            delete this.items[ key ];
        });
    }
}