export default {
    'world': {
        name: 'world',
        type: 'gltfModel',
        path: 'models/world-v3.gltf'
    },
    'singapore': {
        name: 'singapore',
        type: 'gltfModel',
        path: 'models/world/singapore.gltf'
    },
    'grass': {
        name: 'grass',
        type: 'gltfModel',
        path: 'models/grass.gltf'
    },
    'big-plane': {
        name: 'big-plane',
        type: 'gltfModel',
        path: 'models/ba-plane.gltf'
    },
    'mid-plane': {
        name: 'mid-plane',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'small-plane': {
        name: 'small-plane',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'car': {
        name: 'car',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'coach': {
        name: 'coach',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'camper-van': {
        name: 'camper-van',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'boat': {
        name: 'boat',
        type: 'gltfModel',
        path: 'models/plane-large.gltf'
    },
    'water-normal': {
        name: 'water-normal',
        type: 'texture',
        path: 'textures/water/waternormals.jpg'
    },
    'cloud': {
        name: 'cloud',
        type: 'texture',
        path: 'textures/cloud/cloud.jpg'
    },
    'flag-singapore': {
        name: 'flag-singapore',
        type: 'texture',
        path: 'textures/flags/SN-flag.jpg'
    },
    'flag-indonesia': {
        name: 'flag-indonesia',
        type: 'texture',
        path: 'textures/flags/ID-flag.jpg'
    },
    'flag-australia': {
        name: 'flag-australia',
        type: 'texture',
        path: 'textures/flags/AS-flag.jpg'
    },
    'flag-new-zealand': {
        name: 'flag-new-zealand',
        type: 'texture',
        path: 'textures/flags/NZ-flag.jpg'
    },
    'flag-fiji': {
        name: 'flag-fiji',
        type: 'texture',
        path: 'textures/flags/FJ-flag.jpg'
    },
    'flag-philippines': {
        name: 'flag-philippines',
        type: 'texture',
        path: 'textures/flags/RP-flag.jpg'
    },
    'flag-thailand': {
        name: 'flag-thailand',
        type: 'texture',
        path: 'textures/flags/TH-flag.jpg'
    },
    'flag-laos': {
        name: 'flag-laos',
        type: 'texture',
        path: 'textures/flags/LA-flag.jpg'
    },
    'flag-vietnam': {
        name: 'flag-vietnam',
        type: 'texture',
        path: 'textures/flags/VM-flag.jpg'
    },
    'flag-cambodia': {
        name: 'flag-cambodia',
        type: 'texture',
        path: 'textures/flags/CB-flag.jpg'
    },
    'palm': {
        name: 'palm',
        type: 'gltfModel',
        path: 'models/palm.gltf'
    },
    'marina-bay-sands': {
        name: 'marina-bay-sands',
        type: 'gltfModel',
        path: 'models/marina-bay-sands.gltf'
    },
    'test': {
        name: 'test',
        type: 'gltfModel',
        path: 'models/test.gltf'
    },
    'singapore-flyer': {
        name: 'singapore-flyer',
        type: 'gltfModel',
        path: 'models/flyer.gltf'
    },
    'building-a': {
        name: 'building-a',
        type: 'gltfModel',
        path: 'models/building-A.gltf'
    },
    'building-b': {
        name: 'building-b',
        type: 'gltfModel',
        path: 'models/building-B.gltf'
    },
    'building-c': {
        name: 'building-c',
        type: 'gltfModel',
        path: 'models/building-C.gltf'
    }
}