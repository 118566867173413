// Styling
import './Styling/main.scss';

// Logic
import ContentService from './Services/Content.js';
import StateService from './Services/State.js';

// Rendering
import Experience from './Experience/Experience.js';
import UI from './UI/UI.js';

// Variables
let error = null;
let splash = null;

let ui = null;
let experience = null;
let timer = null;

// Functions
const showError = () => {
    error = document.createElement( 'div' );
    error.className = 'Error';
    error.innerHTML = `
        <h1 class='error-title title-l'>The application could not start</h1>
        <p class='error-text text'>Please check your network connection</p>
        <button class='button-primary' type='button' onclick='window.location.reload()'>
            Try Again
            <div class="split"></div>
        </button>
    `;
    document.body.appendChild( error );
}

const showSplash = () => {
    splash = document.createElement( 'div' );
    splash.className = 'Splash transform-in';

    // const checked = localStorage.getItem( 'simple' ) === 'yes';

    // <div class='checkbox'>
    //     <input type='checkbox' class='checkbox-input' id='simple' ${ checked ? 'checked' : '' } /> 
    //     <label class='label text-s' for='simple'>Want to skip the fancy part? Check here to remove the 3D elements and see the blog in traditional format</label>
    // </div>

    splash.innerHTML = `
        <div class="image-container">
            <div class="content">
                <h1 class='splash-title title-xxs weight-regular'>Beth and Lawrence Travels</h1>
                <h2 class='splash-subtitle title-l weight-bold'>Our 6 Month Adventure</h2>
                <div class='button-container'>
                    <button class='button-primary' id='submit'>
                        Explore Our Adventure
                        <div class="split"></div>
                    </button>
                </div>
            </div>
        </div>
    `;

    document.body.appendChild( splash );
    document.getElementById( 'submit' ).addEventListener( 'click', start );

    timer = setTimeout( () => splash.classList.remove( 'transform-in' ), 1000 );
}

const removeSplash = () => {
    if ( document.getElementById( 'submit' ) ) document.getElementById( 'submit' ).removeEventListener( 'click', start );
    if ( splash ) splash.remove();
}

const setupExperience = () => {
    const element = document.createElement( 'canvas' );
    document.body.appendChild( element );
    experience = new Experience( element );
}

const setupUI = () => {
    const element = document.createElement( 'main' );
    document.body.appendChild( element );
    ui = new UI( element );
}

const onInit = () => {
    showSplash();
}

const start = () => {
    splash.classList.add( 'transform-out' );

    timer = setTimeout( () => {
        // const simple = document.getElementById( 'simple' ).checked;
        // localStorage.setItem( 'simple', simple ? 'yes' : 'no' );
        const simple = true;
        removeSplash();
        bootup( simple );
    }, 1000 );
}

// Lifecycle - Application Bootup
const bootup = async ( simple ) => {
    try {
        setupUI();

        if ( !simple ) {
            setupExperience();
        }
        
        await ContentService.onInit();

        const searchParams = new URLSearchParams( window.location.search );
        if ( !searchParams.has( 'skip' ) && ContentService.holding ) {
            ui.onHolding();
            StateService.stateHolding();
        } else {
            StateService.onInit( simple );
            ui.onReady();

            if ( simple ) {
                StateService.stateInitialReady();
            }
        }
    } catch ( err ) {
        console.log( 'Error starting the application', err );
        showError();
    }
}

onInit();