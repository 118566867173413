/** Classes */
import Content from "../../Services/Content.js";
import State from "../../Services/State.js";

/** Components */

let instance = null;

export default class DateSelector {
    constructor( _element ) {

        if (instance) return instance;
        instance = this;

        /** Init */
        this.element = _element;
        this.create();
    }

    select( id ) {
        console.log( 'id', id );
    }

    create() {
        const entries = Content.getDiaryEntries();

        this.element.className = 'DateSelector';
        this.element.innerHTML = `
            <div class="timeline">
                <div class="wrapper" id="date-selector-wrapper"></div>
            </div>
        `;

        const items = entries.map( ( entry ) => {
            const item = document.createElement( 'div' );
            const status = State.entries[ entry._uid ];
            item.className = `item ${ status || '' }`;
            item.id = `date-selector-item-${ entry._uid }`;
            item.onclick = () => this.select( entry._uid );
            item.innerHTML = `
                <div class="marker"></div>
                <p class="name text-xs">${ entry.name }</p>
            `;
            this.element.querySelector( '#date-selector-wrapper' ).appendChild( item );
        })
    }

    onStatusUpdate( data ) {
        const matched = document.getElementById( `date-selector-item-${ data.id }` );
        if ( matched ) {
            matched.classList.remove( 'pending', 'active', 'completed' );
            matched.classList.add( data.status );
        }
    }

    onStateUpdate( state ) {
        const index = Content.getDiaryEntryIndexById( state );
        this.element.querySelector( '#date-selector-wrapper' ).style.transform = `translateX( -${ index * 8 }rem )`;
        // Apply updated state changes

        const elements = this.element.querySelectorAll( '.item' );
        elements.forEach( ( element ) => {
            element.classList.remove( 'current' );
            if ( element.id === `date-selector-item-${ state }` ) {
                element.classList.add( 'current' );
            }
        } )

    }

    destroy() {
        instance = null;
    }
}