/** Services */
// import ContentService from "../../Services/Content.js";

/** Variables */
let instance = null;

export default class Loading {
    constructor( _element ) {
        // Singleton
        if (instance) return instance;
        instance = this;

        /** Elements */
        this.element = _element;
        // this.data = ContentService.data.global[ 0 ].loading[ 0 ];

        this.onInit();
    }

    /** Functions */
    create() {
        this.element.className = 'Loading';
        this.element.innerHTML = `
            <h1>Loading</h1>
            <h3>Getting Passports</h1>
        `;
    }

    /** Lifecycles */
    onInit() {
        this.create();
    }

    destroy() {
        instance = null;
    }
}