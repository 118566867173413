/** Libraries */
import * as THREE from 'three';
import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js';

/** Classes */
import Experience from '../Experience.js';

/** Objects */
import Grass from './Grass/Grass.js';

export default class Model {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;

        // Resource
        this.model = null;
        // this.grass = null;
        this.updating = [];

        // this.initGrassMaterial();
        this.initModel();
        // this.initGrass();
    }

    initModel() {
        this.model = this.resources.items.world.scene.clone();
        this.model.traverse( ( child ) => {
            if ( child instanceof THREE.Mesh ) {
                child.castShadow = true;
                child.receiveShadow = true;
            }

            if ( child.isMesh && child.material.isMeshStandardMaterial ) {
                child.material.envMapIntensity = 1;
            }
        })

        // this.scene.add( this.model );


        // console.log( 'this.resources.items.singapore.scene', this.resources.items )
        // console.log( 'this.resources.items.singapore.scene', this.resources.items.singapore );
        const singaporeNew = this.resources.items.singapore.scene;
        singaporeNew.position.set( 0, 0, 0 );
        singaporeNew.traverse( ( child ) => {
            if ( child instanceof THREE.Mesh ) {
                child.castShadow = true;
                child.receiveShadow = true;
            }

            if ( child.isMesh && child.material.isMeshStandardMaterial ) {
                child.material.envMapIntensity = 1;
            }
        })
        this.scene.add( singaporeNew );
        // console.log( 'signapore new', singaporeNew );


        // Set the shadow material - this renders only the shadows in the scene
        const material = new THREE.ShadowMaterial();
        // const material = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
        material.opacity = 0.5;

        // Set the geometry plane and rotate to horizontal
        const geometry = new THREE.PlaneGeometry( 5, 5 );
        geometry.rotateX( - Math.PI / 2 );

        // Set the mesh position
        const mesh = new THREE.Mesh( geometry, material );
        mesh.receiveShadow = true;
        mesh.position.set( 5.95, 0.085, 84.1 );
        // Give unique name so we can find the mesh later
        mesh.name = `ShadowPlane`;
        mesh.renderOrder = 1;

        // Add the mesh to the scene
        this.scene.add( mesh );



    }

    initGrass() {
        // const geometry = new THREE.BoxGeometry( 0.5, 0.5, 0.5 );
        // const material = new THREE.MeshStandardMaterial( { color: 0xff0000 } );

        // this.blade = this.resources.items.grass.scene;

        this.model.traverse( ( object ) => {
            if ( object instanceof THREE.Mesh && object.material.name.includes( 'Grass' ) ) {

                const grass = new Grass( object );

                // const numBlades = 1000;
                // const sampler = new MeshSurfaceSampler( object )
                //     .build();

                // let objectScale = new THREE.Vector3();
                // let objectPosition = new THREE.Vector3();
                // let quaternion = new THREE.Quaternion();
                // object.getWorldScale( objectScale );
                // object.getWorldPosition( objectPosition );
                // object.getWorldQuaternion( quaternion );

                // const grass = new THREE.InstancedMesh( this.blade.children[ 0 ].geometry, this.blade.children[ 0 ].material, numBlades );
                // grass.applyQuaternion( quaternion );
                // grass.position.set( objectPosition.x, objectPosition.y, objectPosition.z )
                // grass.scale.set( 0.5, 0.5, 0.5 );

                // const empty = new THREE.Object3D();
                // const position = new THREE.Vector3();

                // for ( let i = 0; i < numBlades; i++ ) {
                //     sampler.sample( position );

                //     const newPosition = new THREE.Vector3(
                //         ( position.x * objectScale.x ),
                //         ( position.y * objectScale.y ),
                //         ( position.z * objectScale.z ),
                //     )

                //     empty.position.copy( newPosition );
                //     empty.updateMatrix();

                //     grass.setMatrixAt( i, empty.matrix );
                // }

                // grass.instanceMatrix.needsUpdate = true;

                // this.scene.add( grass );
            }
        })
    }

    onStateUpdate( instant ) {
        if ( !this.experience.state.model_visible ) return;
        
        this.updating.forEach( ( item ) => {
            if ( !item.finished ) {
                item.callback();
                clearTimeout( item.timer );
            }
        });
        this.updating = [];

        for ( let i = 0; i < this.experience.state.model_visible.length; i++ ) {
            const item = this.experience.state.model_visible[ i ];
            const model = this.model.getObjectByName( item.name );

            if ( item.visible !== model.visible ) {
                let finished = false;
                const callback = () => {
                    model.visible = item.visible;
                    finished = true;
                }

                if ( instant ) {
                    callback();
                } else {
                    const timer = setTimeout( callback, parseFloat( item.change_state_delay ) * 1000 );
                    this.updating.push( { timer, callback, finished } );
                }
            }
        }
    }

    update() {
        // if ( this.grass ) this.grass.update();
    }
}
