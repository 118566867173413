/** Libraries */
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';

/** Classes */
import Debug from './Utils/Debug.js';
import Resources from './Utils/Resources.js';
import Sizes from './Utils/Sizes.js';
import Time from './Utils/Time.js';
import Camera from './Camera.js';
import Renderer from './Renderer.js';
import World from './World/World.js';

/** Services */
import Content from '../Services/Content.js';
import State from '../Services/State.js';

/** Resources */
import sources from './sources.js';

let instance = null;

export default class Experience {
    constructor( _canvas ) {

        if (instance) {
            return instance;
        }

        instance = this;

        // Global access
        window.experience = this;

        /** Bindings */
        this.onStateUpdate = this.onStateUpdate.bind( this );

        // Setup
        this.debug = new Debug();
        this.ready = false;

        this.canvas = _canvas;
        this.sizes = new Sizes();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.resources = new Resources( sources );
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.world = new World();

        // Resize event
        this.sizes.on( 'resize', () => this.resize() );

        // Time tick event
        this.time.on( 'tick', () => this.update() );

        State.addListener( this.onStateUpdate );
    }

    resize() {
        this.camera.resize();
        this.renderer.resize();
    }

    update() {
        if ( this.ready ) {
            this.camera.update();
            this.world.update();
            this.renderer.update();
            TWEEN.update();
        }
    }

    findRequiredAssets( states ) {
        console.log( '****states****', states );
        
        const scenery = states
            .map( ( state ) => state.scenery && state.scenery.length ? state.scenery.map( ( item ) => item.model ) : [] )
            .reduce( ( acc, next ) => Array.from( new Set( [ ...acc, ...next  ] ) ), [] );

        const trackers = states
            .map( ( state ) => state.timeline && state.timeline.length ? state.timeline.map( ( item ) => item.model_type ) : [] )
            .reduce( ( acc, next ) => Array.from( new Set( [ ...acc, ...next  ] ) ), [] )
            .filter( ( item ) => item !== 'none' );

        const constants = [ 'singapore', 'water-normal', 'world', 'grass', 'building-a', 'building-b', 'building-c' ];

        return Array.from( new Set( [ ...scenery, ...trackers, ...constants ] ) );
    }

    async initateStateChange( data ) {
        const previousState = Content.getPreviousDiaryEntryById( data );
        const currentState = Content.getDiaryEntryById( data );
        const requiredAssets = this.findRequiredAssets( [ previousState, currentState ] );
        await this.resources.handleStateChange( requiredAssets );
        
        if ( this.ready ) {
            State.stateChangeReady();
        } else {
            this.world.onInit();
            this.ready = true;
            State.stateInitialReady();
        }

    }

    onStateUpdate( event, data ) {
        if ( event === 'initiate-diary' ) {
            this.initateStateChange( data );
        }

        if ( event === 'diary' ) {
            const updateState = ( id ) => {
                this.state = Content.getDiaryEntryById( id );
                this.camera.onStateUpdate();
                this.world.onStateUpdate();
            }

            if ( this.state && Content.findIfContinuingJourney( this.state._uid, data ) ) {
                updateState( data );
            } else {
                const previousState = Content.getPreviousDiaryEntryById( data );
                this.state = previousState;
                this.camera.onStateUpdate( true );
                this.world.onStateUpdate( true );

                // This needs to be a millisecond value to allow an update to happen
                setTimeout( () => updateState( data ), 34 );
            }
        }

        if ( event === 'camera-offset' ) {
            this.camera.applyOffset( data );
        }
    }

    destroy() {
        State.removeListener( this.onStateUpdate );

        this.sizes.off( 'resize' );
        this.time.off( 'tick' );
        this.resources.off( 'ready' );

        // Traverse the whole scene
        this.scene.traverse( ( child ) => {
            // Test if it's a mesh
            if ( child instanceof THREE.Mesh ) {
                child.geometry.dispose();

                // Loop through the material properties
                for ( const key in child.material ) {
                    const value = child.material[ key ];

                    // Test if there is a dispose function
                    if ( value && typeof value.dispose === 'function' ) {
                        value.dispose();
                    }
                }
            }
        } );

        this.camera.controls.dispose();
        this.renderer.instance.dispose();

        if ( this.debug.active ) {
            this.debug.ui.destroy();
        }
    }
}