/** Variables */
let instance = null;

export default class HoldingPage {
    constructor( _element ) {
        // Singleton
        if (instance) return instance;
        instance = this;

        /** Elements */
        this.element = _element;

        this.onInit();
    }

    /** Functions */
    getDifference() {
        const current = new Date();
        const target = new Date( 2024, 8, 9, 19, 35, 0, 0);
        const utc1 = Date.UTC( current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(),  current.getMinutes(), current.getSeconds() );
        const utc2 = Date.UTC( target.getFullYear(), target.getMonth(), target.getDate(), target.getHours(),  target.getMinutes(), target.getSeconds() );

        return Math.floor( utc2 - utc1 );
    }

    getTime( difference ) {

        // Check if time until the event starts
        if ( difference >= 0 ) {
            // Convert ms difference to seconds
            let delta = Math.abs( difference ) / 1000;

            // Get Days left
            const days = Math.floor( delta / 86400 );
            delta -= ( days * 86400 );

            // Get Hours left
            const hours = Math.floor( delta / 3600 ) % 24;
            delta -= ( hours * 3600 );

            // Get Minutes left
            const minutes = Math.floor( delta / 60 ) % 60;
            delta -= minutes * 60;

            // Get Seconds left
            const seconds = Math.floor(delta % 60);

            // Store different sections in the array
            return [ Math.max( days, 0 ), Math.max( hours, 0 ), Math.max( minutes, 0 ), Math.max( seconds, 0 ) ]
                .map( ( item ) => ( '0' + item ).slice( -2 ) );
        } else {
            // Reached start time - stop the timer
            clearInterval(this.interval);
            return [ 0, 0, 0, 0 ];
        }
    }

    renderTime( timeArray ) {
        document.getElementById( 'countdown-days' ).innerHTML = timeArray[ 0 ];
        document.getElementById( 'countdown-hours' ).innerHTML = timeArray[ 1 ];
        document.getElementById( 'countdown-minutes' ).innerHTML = timeArray[ 2 ];
        document.getElementById( 'countdown-seconds' ).innerHTML = timeArray[ 3 ];
    }

    startTimer() {
        let time = 1000;
        let difference = this.getDifference();

        // Initial render
        const array = this.getTime( difference );
        this.renderTime( array );

        this.timer = setInterval( () => {
            difference -= time;
            const array = this.getTime( difference );
            this.renderTime( array );
        }, time );
    }

    create() {
        this.element.className = 'HoldingPage transform-in';
        this.element.innerHTML = `
            <div class="image-container">
                <div class="content">
                    <h1 class='holding-title title-m'>Ready for takeoff!</h1>
                    <p class='holding-subtitle title-xxs'>Our adventure starts on the 9th September!</p>

                    <svg class="countdown" viewBox="0 0 720 200" xmlns="http://www.w3.org/2000/svg">
                        <text
                            x="12.5%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            id="countdown-days"></text>
                        <text
                            x="37.5%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            id="countdown-hours"></text>
                        <text
                            x="62.5%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            id="countdown-minutes"></text>
                        <text
                            x="87.5%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            id="countdown-seconds"></text>
                        <text
                            class="separator"
                            x="25%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle">:</text>
                        <text
                            class="separator"
                            x="50%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle">:</text>
                        <text
                            class="separator"
                            x="75%"
                            y="50%"
                            dominant-baseline="middle"
                            text-anchor="middle">:</text>


                        <text
                            class="units"
                            x="12.5%"
                            y="80%"
                            dominant-baseline="middle"
                            text-anchor="middle">DAYS</text>
                        <text
                            class="units"
                            x="37.5%"
                            y="80%"
                            dominant-baseline="middle"
                            text-anchor="middle">HOURS</text>
                        <text
                            class="units"
                            x="62.5%"
                            y="80%"
                            dominant-baseline="middle"
                            text-anchor="middle">MINUTES</text>
                        <text
                            class="units"
                            x="87.5%"
                            y="80%"
                            dominant-baseline="middle"
                            text-anchor="middle">SECONDS</text>
                    </svg>

                </div>
            </div>
        `;

        this.startTimer();

        setTimeout( () => this.element.classList.remove( 'transform-in' ) );
    }

    /** Lifecycles */
    onInit() {
        this.create();
    }

    onClose() {
        this.element.classList.add( 'transform-out' );
    }

    destroy() {
        clearInterval( this.timer );
        instance = null;
    }
}
