/** Services */
import Content from "../../Services/Content.js";
import State from "../../Services/State.js";

/** Components */
import DiaryList from "../Core/DiaryList.js";
import DiaryPage from "../Core/DiaryPage.js";
import Header from "../Header/Header.js";

/** Variables */
let instance = null;

export default class SimplePage {
    constructor( _element ) {
        // Singleton
        if (instance) return instance;
        instance = this;

        /** Bindings */
        this.completed = this.completed.bind( this );
        this.previous = this.previous.bind( this );
        this.next = this.next.bind( this );

        /** Elements */
        this.element = _element;

        this.currentId = null;
        this.nextItem = null;
        this.previousItem = null;

        this.list = null;
        this.header = null;

        this.diaryPage = null;
        this.diaryPageElement = null;

        this.onInit();
    }

    /** Functions */

    /** Adds the header to page */
    addHeader() {
        if ( this.header ) return;
        const hideMenu = true;
        this.header = new Header( document.getElementById( 'SimplePage-header' ), hideMenu );
    }

    /** Adds All Days Menu to the screen - Never Removed */
    addList() {
        if ( this.list ) return;
        this.listElement = document.createElement( 'div' );
        document.getElementById( 'SimplePage-diarylist' ).appendChild( this.listElement );
        this.list = new DiaryList( this.listElement );
    }

    /** Shows the Diary Page */
    showPage() {
        if ( !this.page ) {
            this.pageElement = document.createElement( 'div' );
            document.getElementById( 'SimplePage-diarypage' ).appendChild( this.pageElement );
            document.getElementById( 'SimplePage-diarypage' ).scroll( 0, 0 );

            const pageData = Content.getDiaryEntryById( this.currentId ).content[ 0 ];
            this.nextItem = Content.getNextDiaryEntryById( this.currentId );
            this.previousItem = Content.getPreviousDiaryEntryById( this.currentId );

            this.page = new DiaryPage(
                this.pageElement,
                pageData,
                this.completed,
                this.previousItem && this.previousItem.name !== "Pre State" ? { title: this.previousItem.name, callback: this.previous } : null,
                this.nextItem ? { title: this.nextItem.name, callback: this.next } : null
            );
        }
    }

    /** Removes the Diary Page */
    hidePage() {
        if ( this.page ) this.page.destroy();
        if ( this.pageElement ) this.pageElement.remove();
        this.nextItem = null;
        this.previousItem = null;
        this.page = null;
        this.pageElement = null;
    }

    /** User has reached end of the page */
    completed() {
        if ( this.currentId ) State.changeEntryStatus( this.currentId, 'completed' );
    }

    /** Change state to previous item */
    previous() {
        if ( this.previousItem._uid ) State.currentId = this.previousItem._uid;
    }

    /** Change state to next item */
    next() {
        if ( this.nextItem._uid ) State.currentId = this.nextItem._uid;
    }

    /** There is a change to the active page */
    entryUpdate( data ) {
        if ( this.currentId && State.entries[ this.currentId ] !== 'completed' ) State.changeEntryStatus( this.currentId, 'pending' );

        this.currentId = data;
        if ( this.currentId && State.entries[ this.currentId ] !== 'completed' ) State.changeEntryStatus( this.currentId, 'active' );

        this.hidePage();
        this.showPage();
    }

    /** Lifecycles */
    onInit() {
        this.element.className = 'SimplePage';

        this.element.innerHTML = `
            <header id="SimplePage-header"></header>
            <div id="SimplePage-diarypage"></div>
            <div id="SimplePage-diarylist"></div>
        `;

        this.addHeader();
        this.addList();
    }

    destroy() {
        instance = null;
    }
}