/** Libraries */
import * as THREE from 'three';

/** Classes */
import Experience from '../Experience.js';
import Content from '../../Services/Content.js';

export default class Lights {
    constructor( ) {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder(`lights`).close();
        }

        // Updating State
        this.updating = null;

        // Lights
        this.directionalLight = null;
        this.hemisphereLight = null;
        this.target = null;
        this.helper = null;

        this.createStatic();
    }

    // Set initial lighting
    // TODO: Directional Light dummy values?
    createStatic() {
        this.hemisphereLight = new THREE.HemisphereLight( 0xffffcc, 0xffffff, 1 );
        this.target = new THREE.Object3D();
        this.target.position.set( 0, 0, 0 );

        this.scene.add( this.hemisphereLight, this.target );
    }

    createDirectional( settings ) {
        // Remove old objects
        if ( this.directionalLight ) {
            this.scene.remove( this.directionalLight );
            this.directionalLight.dispose();
            this.directionalLight = null;
        }

        if ( this.helper ) {
            this.scene.remove( this.helper );
            this.helper.dispose();
            this.helper = null;
        }

        // TODO: Remove debug options
        if (this.debug.active) {
            // this.debugFolder.remove();
        }
        
        // Initial Light Settings
        this.directionalLight = new THREE.DirectionalLight(
            new THREE.Color( parseFloat( settings.color[ 0 ].r ), parseFloat( settings.color[ 0 ].g ), parseFloat( settings.color[ 0 ].b ) ),
            parseFloat( settings.intensity )
        );

        // // Position
        this.directionalLight.position.set(
            parseFloat( settings.position[ 0 ].x ),
            parseFloat( settings.position[ 0 ].y ),
            parseFloat( settings.position[ 0 ].z )
        );

        // Target
        this.target.position.set(
            parseFloat( settings.target[ 0 ].x ),
            parseFloat( settings.target[ 0 ].y ),
            parseFloat( settings.target[ 0 ].z )
        )
        this.directionalLight.target = this.target;

        // Shadow
        this.directionalLight.castShadow = settings.cast_shadow;
        this.directionalLight.shadow.mapSize.set( parseFloat( settings.shadow[ 0 ].map_size ), parseFloat( settings.shadow[ 0 ].map_size ) );
        this.directionalLight.shadow.camera.near = parseFloat( settings.shadow[ 0 ].near );
        this.directionalLight.shadow.camera.far = parseFloat( settings.shadow[ 0 ].far );
        this.directionalLight.shadow.camera.top = parseFloat( settings.shadow[ 0 ].top );
        this.directionalLight.shadow.camera.bottom = parseFloat( settings.shadow[ 0 ].bottom );
        this.directionalLight.shadow.camera.left = parseFloat( settings.shadow[ 0 ].left );
        this.directionalLight.shadow.camera.right = parseFloat( settings.shadow[ 0 ].right );
        this.directionalLight.shadow.bias = parseFloat( settings.shadow[ 0 ].bias );

        this.scene.add( this.directionalLight );

        // A helper to show the camera used to render the shadows
        if ( this.debug.active ) {
            this.helper = new THREE.CameraHelper( this.directionalLight.shadow.camera );
            this.scene.add( this.helper );
        }

        // TODO: ADD AND REMOVE DEBUG OPTIONS
        // Debugging
        if ( this.debug.active ) {
            // this.debugFolder.add( this.directionalLight, 'intensity', 0, 20, 0.1 ).name( 'Light Intensity' );
            // this.debugFolder.add( this.directionalLight.position, 'x', -500, 500, 0.01 ).name( 'Pos X' );
            // this.debugFolder.add( this.directionalLight.position, 'y', -500, 500, 0.01 ).name( 'Pos Y' );
            // this.debugFolder.add( this.directionalLight.position, 'z', -500, 500, 0.01 ).name( 'Pos Z' );
            // this.debugFolder.add( this.target.position, 'x', -500, 500, 0.01 ).name( 'Target X' );
            // this.debugFolder.add( this.target.position, 'y', -500, 500, 0.01 ).name( 'Target Y' );
            // this.debugFolder.add( this.target.position, 'z', -500, 500, 0.01 ).name( 'Target Z' );
            // this.debugFolder.add( this.directionalLight.shadow, 'bias', -0.1, 0.1, 0.0001 ).name( 'Bias' );
        }
    }

    // Set new sun position
    set( update, instant ) {

        if ( this.updating && !this.updating.finished ) {
            this.updating.callback();
            clearTimeout( this.updating.timer );
        }
        this.updating = null;

        const settings = Content.getLightSettingsById( update.id );
        if ( !settings ) return;

        let finished = false;
        const callback = () => {
            this.createDirectional( settings );
            finished = true;
        }
        const timer = setTimeout( callback, instant ? 0 : parseFloat( update.change_at ) * 1000 );

        this.updating = { timer, callback, finished };
    }

    onStateUpdate( instant ) {
        if ( !this.experience.state.light ) return;
        this.set( this.experience.state.light[ 0 ], instant );
    }
}