/** Classes */
import StateService from "../../Services/State.js";

/** Components */
import DiaryList from "../Core/DiaryList.js";

let instance = null;

export default class Menu {
    constructor( _element ) {

        if (instance) return instance;
        instance = this;

        /** Bindings */
        this.close = this.close.bind( this );
        this.stateUpdate = this.stateUpdate.bind( this );

        /** Init */
        this.element = _element;
        this.list = null;

        this.onInit();
    }
    
    outsideClick( event ) {
        let currentElement = event.srcElement;
        let found = false;
        while ( currentElement ) {
            if ( [ 'menu-content' ].includes( currentElement.id ) ) {
                found = true;
                currentElement = null;
            } else {
                currentElement = currentElement.parentElement;
            }
        }

        if ( !found ) this.close();
    }

    close() {
        StateService.menu = false;
    }

    create() {
        this.element.className = 'Menu hidden transform-in';
        this.element.innerHTML = `
            <div class="menu-content" id="menu-content">
                <button type="button" class="close" id="close-menu">
                    <img class="close-icon" src="/images/close/close.svg" />
                </button>
                <div id="menu-list"></div>
            </div>
        `;

        this.list = new DiaryList( document.getElementById( 'menu-list' ), this.close );
        document.getElementById( 'close-menu' ).onclick = this.close;
        this.element.onclick = this.outsideClick;
    }

    stateUpdate( event, data ) {
        if ( event === 'menu' ) {
            if ( data ) {
                this.element.classList.remove( 'hidden' );
                setTimeout( () => this.element.classList.remove( 'transform-in' ) );
            } else {
                this.element.classList.add( 'transform-out' );
                setTimeout( () => {
                    this.element.classList.add( 'hidden' );
                    this.element.classList.remove( 'transform-out' );
                    this.element.classList.add( 'transform-in' );
                }, 500 )
            }
        }
    }

    onInit() {
        this.create();
        StateService.addListener( this.stateUpdate );
    }

    destroy() {
        instance = null;
    }
}